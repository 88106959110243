










































































@import '@design';
.menu-item.condensed {
  position: relative;
  .item-price {
    font-weight: normal;
  }
}
