


































































@import '@design';

.menu-section--title {
  font-size: 60px;
  line-height: 0.7;
}
.menu-section {
  &.callout {
    // max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid #979797;
    .menu-section--items {
      margin: 1em 0;
    }
  }
}
