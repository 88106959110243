











































@import '@design';
.callout-item {
  .menu-item--prices {
    font-weight: normal !important;
    text-align: left !important;
  }
}
